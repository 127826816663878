import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import checkToken from "src/hooks/checkToken";
import PropTypes from "prop-types";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedNome = localStorage.getItem("nome");
    const storedEmail = localStorage.getItem("email");
    const storedLojas = JSON.parse(localStorage.getItem("lojas"));
    const storedPermissoes = localStorage.getItem("permissoes");
    const storedCargoId = localStorage.getItem("cargoId");
    const storedCargo = localStorage.getItem("cargo");

    return storedNome
      ? {
          nome: storedNome,
          email: storedEmail,
          lojas: storedLojas,
          permissoes: storedPermissoes ? JSON.parse(storedPermissoes) : null,
          cargoId: storedCargoId,
          cargo: storedCargo,
        }
      : null;
  });
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [cargoId, setCargoId] = useState(
    localStorage.getItem("cargoId") || null,
  );
  const [cargo, setCargo] = useState(localStorage.getItem("cargo") || null);
  const [permissoes, setPermissoes] = useState(() => {
    const storedPermissoes = localStorage.getItem("permissoes");
    return storedPermissoes ? JSON.parse(storedPermissoes) : null;
  });
  const navigate = useNavigate();

  const login = async ({
    nome,
    email,
    cargoId,
    cargo,
    lojas,
    permissoes,
    accessToken,
  }) => {
    setToken(accessToken);
    setUser({ nome, email, cargoId, lojas, permissoes });
    setCargoId(Number(cargoId));
    setCargo(cargo);
    setPermissoes(permissoes);

    localStorage.setItem("token", accessToken);
    localStorage.setItem("nome", nome);
    localStorage.setItem("email", email);
    localStorage.setItem("cargoId", cargoId);
    localStorage.setItem("cargo", cargo);
    localStorage.setItem("lojas", JSON.stringify(lojas));
    localStorage.setItem("permissoes", JSON.stringify(permissoes));

    navigate("/dashboard");
  };

  const logout = useCallback(() => {
    //setToken(null);
    //setUser(null);
    //setCargoId(null);
    //setCargo(null);
    localStorage.removeItem("token");
    localStorage.removeItem("nome");
    localStorage.removeItem("email");
    localStorage.removeItem("cargoId");
    localStorage.removeItem("cargo");
    localStorage.removeItem("lojas");
    localStorage.removeItem("permissoes");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userType");
    localStorage.removeItem("userName");

    navigate("/login");
  }, [navigate]);

  useEffect(() => {
    const verifyToken = async () => {
      const isValid = await checkToken();
      if (!isValid) {
        logout();
      }
    };

    if (token) {
      verifyToken();
    }
  }, [token, logout]);

  return (
    <AuthContext.Provider
      value={{ user, token, cargoId, cargo, permissoes, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
