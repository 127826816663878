import api from '../services/api_requests';

const checkToken = async () => {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }

  try {
   
    const response = await api.get('/usuario/login/info', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    
    if (response.status === 200 && response.data.status) {
      return true;
    }else if (response.status === 200 && !response.data.status) {
      // console.log(response.data)
      console.log('Usuario Desativado ou Inativo');
      return false;
    }
  } catch (error) {
    console.error('Erro ao validar token:', error);
    return false; 
  }

  return false; 
};

export default checkToken;